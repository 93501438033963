import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ChartGridState } from '../../enums/general.enum';
import { IChartData } from '../../models/chart.model';

@Component({
  selector: 'app-chart-grid',
  templateUrl: './chart-grid.component.html',
  styleUrls: ['./chart-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartGridComponent implements OnInit {

  @Input() chartGridState: ChartGridState;
  @Input() chartData: IChartData[];

  @Output() expandChart = new EventEmitter<string>();

  notInitializedState = ChartGridState.NOT_INITIALIZED;
  resultNotFoundState = ChartGridState.RESULT_NOT_FOUND;
  processingState = ChartGridState.PROCESSING;
  renderingState = ChartGridState.RENDERING;

  columnNumber = 3;

  constructor() { }

  ngOnInit(): void {
  }

  trackByChartName(index: number, chart: IChartData) {
    return chart.tagName;
  }

  onResize(newWidth: number) {
    if (newWidth < 600) {
      this.columnNumber = 1;
    } else if (newWidth < 1000) {
      this.columnNumber = 2;
    } else if (newWidth < 1600) {
      this.columnNumber = 3;
    } else {
      this.columnNumber = 4;
    }
  }

}
