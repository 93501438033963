import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IFilterDataProAndSite, IFilterDataGo, IFilterDataFP, IFilterDataCloud, IBuildData, IBuildResponse, IFilterDataDCP, IFilterDataTIAV17, IFilterDataSystemTest, IFilterDataMBus } from '../../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class ChartApiService {

  constructor(
    private http: HttpClient
  ) { }

  //#region Chart Data APIs
  public getProAndSiteChartData(filterData: IFilterDataProAndSite): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getProAndSiteData`, filterData);
  }

  public getGoChartData(filterData: IFilterDataGo): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getGoData`, filterData);
  }

  public getFPChartData(filterData: IFilterDataFP): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getFPData`, filterData);
  }

  public getCloudChartData(filterData: IFilterDataCloud): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getCloudData`, filterData);
  }

  public getDCPChartData(filterData: IFilterDataDCP): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getDCPData`, filterData);
  }

  public getTIAV17ChartData(filterData: IFilterDataTIAV17): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getTIAV17Data`, filterData);
  }

  public getSystemTestChartData(filterData: IFilterDataSystemTest): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getSystemTestData`, filterData);
  }

  public getKNXChartData(filterData: IFilterDataSystemTest): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getKNXData`, filterData);
  }

  public getMBusChartData(filterData: IFilterDataMBus): Observable<any> {
    return this.http.post(`${environment.resourceServerURL}/charts/getMBusData`, filterData);
  }
  //#endregion

  //#region Build APIs
  public getBuildData(buildData: IBuildData): Observable<IBuildResponse[]> {
    return this.http.post<IBuildResponse[]>(`${environment.resourceServerURL}/charts/getBuildData`, buildData);
  }
  //
}
