import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPCHardwareDetails, IMobileHardwareDetails, IProjectRepo } from '../../models/general.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralApiService {

  public pcHardwareDetails$ = this.getPCHardwareDetails().pipe(shareReplay(1));
  public mobileHardwareDetails$ = this.getMobileHardwareDetails().pipe(shareReplay(1));
  public projectRepoDetails$ = this.getProjectRepoDetails().pipe(shareReplay(1));

  constructor(private _http: HttpClient) { }

  public getPCHardwareDetails(): Observable<IPCHardwareDetails[]> {
    return this._http.get<IPCHardwareDetails[]>(`${environment.resourceServerURL}/info/getPCHardwareDetails`);
  }

  public getMobileHardwareDetails(): Observable<IMobileHardwareDetails[]> {
    return this._http.get<IMobileHardwareDetails[]>(`${environment.resourceServerURL}/info/getMobileHardwareDetails`);
  }

  public getProjectRepoDetails(): Observable<IProjectRepo[]> {
    return this._http.get<IProjectRepo[]>(`${environment.resourceServerURL}/info/getProjectRepoDetails`);
  }
}
