import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductType } from '../../enums/product.enum';
import { IBuildResponse } from '../../models/filter.model';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() productType: ProductType;
  @Input() buildData: IBuildResponse[];
  @Input() filterDisabled: boolean;

  @Output() appliedInclined = new EventEmitter<number>();
  @Output() appliedDeclined = new EventEmitter<number>();
  @Output() appliedAboveAcceptance = new EventEmitter();
  @Output() appliedAboveTarget = new EventEmitter();
  @Output() appliedBaseLine = new EventEmitter<number>();

  @Output() removedAllFilters = new EventEmitter();

  @Output() searchApplied = new EventEmitter<string>();

  inclineActive = 0;
  declineActive = 0;
  aboveAcceptanceActive = false;
  aboveTargetActive = false;
  baseLineActive = '';
  searchTermActive = '';

  // filters: NavbarItem[] = [
  //   {
  //     title: 'Apply Filters',
  //     items: [
  //       { title: 'Inclined Filter',
  //         items: [
  //           { title: 'Inclined by 30%', action: () => this.applyInclinedFilter(30) },
  //           { title: 'Inclined by 20%', action: () => this.applyInclinedFilter(20) },
  //           { title: 'Inclined by 10%', action: () => this.applyInclinedFilter(10) }
  //         ]
  //       },
  //       { title: 'Declined Filter',
  //         items: [
  //           { title: 'Declined by 30%', action: () => this.applyDeclinedFilter(30) },
  //           { title: 'Declined by 20%', action: () => this.applyDeclinedFilter(20) },
  //           { title: 'Declined by 10%', action: () => this.applyDeclinedFilter(10) }
  //         ]
  //       },
  //       { title: 'Above Acceptance', action: () => this.applyAboveAcceptance() },
  //       { title: 'Above Taget', action: () => this.applyAboveTarget() }
  //     ]
  //   }
  // ]

  applyFilterMenuItem: MenuItem[] = [
    {
      label: 'Inclined Filter',
      items:[
        {
            label: 'Inclined by 10%',
            command: () => { this.applyInclinedFilter(10) }
        },
        {
            label: 'Inclined by 20%',
            command: () => { this.applyInclinedFilter(20) }
        },
        {
            label: 'Inclined by 30%',
            command: () => { this.applyInclinedFilter(30) }
        }]
    },
    {
      label: 'Declined Filter',
      items:[
        {
            label: 'Declined by 10%',
            command: () => { this.applyDeclinedFilter(10) }
        },
        {
            label: 'Declined by 20%',
            command: () => { this.applyDeclinedFilter(20) }
        },
        {
            label: 'Declined by 30%',
            command: () => { this.applyDeclinedFilter(30) }
        }]
    },
    {
      label: 'Above Acceptance',
      command: () => { this.applyAboveAcceptance() }
    },
    {
      label: 'Above Target',
      command: () => { this.applyAboveTarget() }
    }
  ];

  baselineMenuItem: MenuItem[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.buildData) {
      this.baselineMenuItem = this.getBaselineMenuItem(this.buildData);
      console.log(this.baselineMenuItem);
    }
  }

  getBaselineMenuItem(buildData: IBuildResponse[]): MenuItem[] {
    const baseLineManuItem: MenuItem[] = [];
    this.buildData.forEach(incData => {
      let menuItem: MenuItem = {};
      menuItem.label = incData.incName;
      menuItem.items = [];
      incData.incData.buildNumber.forEach((buildNumber, index) => {
        menuItem.items.push({
          label: `${incData.incName} - ${buildNumber}`,
          command: () => { this.applyBaseLine(incData.incName, incData.incData.sequenceID[index], buildNumber) }
        });
      })
      baseLineManuItem.push(menuItem);
    });

    return baseLineManuItem;
  }

  applyInclinedFilter(value: number) {
    this.inclineActive = value;
    this.declineActive = 0;
    this.appliedInclined.emit(value);
  }

  applyDeclinedFilter(value: number) {
    this.declineActive = value;
    this.inclineActive = 0;
    this.appliedDeclined.emit(value);
  }

  applyAboveAcceptance() {
    this.aboveAcceptanceActive = true;
    this.appliedAboveAcceptance.emit();
  }

  applyAboveTarget() {
    this.aboveTargetActive = true;
    this.appliedAboveTarget.emit();
  }

  applyBaseLine(incName:string, sequenceID: number, buildNumber: number) {
    this.baseLineActive = `${incName} - ${buildNumber}`;
    this.appliedBaseLine.emit(sequenceID);
  }

  removeFilters() {
    this.inclineActive = 0;
    this.declineActive = 0;
    this.aboveAcceptanceActive = false;
    this.aboveTargetActive = false;
    this.baseLineActive = '';
    this.searchTermActive = '';
    this.removedAllFilters.emit();
  }

  isAnyFilterActive() {
    return this.inclineActive ||
      this.declineActive ||
      this.aboveAcceptanceActive ||
      this.aboveTargetActive ||
      this.baseLineActive.length ||
      this.searchTermActive.length;
  }

  searchEntered(text: string) {
    if (text) {
      this.searchTermActive = text;
      this.searchApplied.emit(text);
    } else {
      this.searchTermActive = '';
      this.searchApplied.emit('');
    }
  }

}
