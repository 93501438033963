import { Component } from '@angular/core';
import { AuthApiService } from 'src/app/shared';
import { Router } from '@angular/router';

@Component({
  templateUrl: './landing.component.html'
})
export class LandingComponent {

  constructor(
    private _authApiService: AuthApiService,
    private _router: Router
  ) { }

  async ngOnInit() {
    if (await this._authApiService.isAuthenticated()) {
      this.redirectIfAuthenticated();
    }
  }

  redirectIfAuthenticated() {
    this._router.navigate(['/main']);
  }

  login() {
    this._authApiService.login();
  }
}
