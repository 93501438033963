import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProgramIncrementListExtended, ITestCaseDataExtended, IRadioOptions } from '../../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterStateService {

  public machineConfigsPro: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true},
    { id: 2, name: 'Medium Config Platform', checked: false},
  ];

  public machineConfigsSite: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true},
    { id: 2, name: 'Medium Config Platform', checked: false},
    { id: 3, name: 'Low Config Platform', checked: false}
  ];

  public machineConfigsGo: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true},
    { id: 2, name: 'Medium Config Platform', checked: false},
    { id: 3, name: 'Low Config Platform', checked: false}
  ];

  public machineConfigsFP: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true},
    { id: 3, name: 'Low Config Platform', checked: false}
  ];

  public machineConfigsCloud: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true},
    { id: 2, name: 'Medium Config Platform', checked: false}
  ];

  public machineConfigsDCP: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true}
  ];

  public machineConfigsTIAV17: IRadioOptions[] = [
    {id: 1, name: 'High Config Platform', checked: true}
  ];

  public machineConfigsST: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true }
  ];

  public machineConfigsKNX: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true }
  ];
  public machineConfigsMBus: IRadioOptions[] = [
    { id: 1, name: 'High Config Platform', checked: true }
  ];

  public deviceTypesFP: IRadioOptions[] = [
    { id: 1, name: 'PXC4.E16', checked: false},
    { id: 2, name: 'PXC5.E003', checked: true},
    { id: 3, name: 'PXC7.E400', checked: false},
    { id: 4, name: 'PXC5.E24', checked: false},
  ];
  public deviceTypesABTGo: IRadioOptions[] = [
    { id: 1, name: 'PXC4.E16', checked: false},
    // { id: 2, name: 'PXC5.E003', checked: true},
    { id: 3, name: 'PXC7.E400', checked: true},
    { id: 4, name: 'PXC5.E24', checked: false},
    { id: 5, name: 'PXC4.M16', checked: false}
  ];
  public platformType: IRadioOptions[] = [
    { id: 1, name: 'Android', checked: true},
    { id: 2, name: 'iOS', checked: false},
    { id: 3, name: 'Windows', checked: false}
  ];

  public networkTypesFP: IRadioOptions[] = [
    { id: 1, name: 'IP', checked: true},
    { id: 2, name: 'MS/TP', checked: false},
    { id: 3, name: 'WLAN', checked: false}
  ];

  public deviceTypesCloud: IRadioOptions[] = [
    { id: 1, name: 'PXC4.E16', checked: true},
    { id: 2, name: 'PXC5.E003', checked: false}
  ];

  public deviceTypesKNX: IRadioOptions[] = [
    { id: 2, name: 'PXC5.E003', checked: false },
    { id: 3, name: 'PXC7.E400', checked: true },
    // { id: 5, name: 'PXC5.E24', checked: false},
    // { id: 4, name: 'PXC5.E40', checked: false},
  ];

  public deviceTypesMBus: IRadioOptions[] = [
    { id: 3, name: 'PXC7.E400', checked: false },
    { id: 4, name: 'PXC5.E24', checked: true }
    // { id: 5, name: 'PXC5.E24', checked: false},
    // { id: 4, name: 'PXC5.E40', checked: false},
  ];
  constructor() { }

  //#region Subjects
  private availablePIListPro = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListSite = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListGo = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListFP = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListCloud = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListDCP = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListTIAV17 = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListSystemTest = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListKNX = new BehaviorSubject<IProgramIncrementListExtended[]>(null);
  private availablePIListMBus = new BehaviorSubject<IProgramIncrementListExtended[]>(null);

  private availableTCListPro = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListSite = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListGo = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListFP = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListCloud = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListDCP = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListTIAV17 = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListSystemTest = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListKNX = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  private availableTCListMBus = new BehaviorSubject<ITestCaseDataExtended[]>(null);
  //#endregion

  //#region Observables
  public availablePIListPro$ = this.availablePIListPro.asObservable();
  public availablePIListSite$ = this.availablePIListSite.asObservable();
  public availablePIListGo$ = this.availablePIListGo.asObservable();
  public availablePIListFP$ = this.availablePIListFP.asObservable();
  public availablePIListCloud$ = this.availablePIListCloud.asObservable();
  public availablePIListDCP$ = this.availablePIListDCP.asObservable();
  public availablePIListTIAV17$ = this.availablePIListTIAV17.asObservable();
  public availablePIListSystemTest$ = this.availablePIListSystemTest.asObservable();
  public availablePIListKNX$ = this.availablePIListKNX.asObservable();
  public availablePIListMBus$ = this.availablePIListMBus.asObservable();

  public availableTCListPro$ = this.availableTCListPro.asObservable();
  public availableTCListSite$ = this.availableTCListSite.asObservable();
  public availableTCListGo$ = this.availableTCListGo.asObservable();
  public availableTCListFP$ = this.availableTCListFP.asObservable();
  public availableTCListCloud$ = this.availableTCListCloud.asObservable();
  public availableTCListDCP$ = this.availableTCListDCP.asObservable();
  public availableTCListTIAV17$ = this.availableTCListTIAV17.asObservable();
  public availableTCListSystemTest$ = this.availableTCListSystemTest.asObservable();
  public availableTCListKNX$ = this.availableTCListKNX.asObservable();
  public availableTCListMBus$ = this.availableTCListMBus.asObservable();
  //#endregion

  //#region Setter Methods
  public changeAvailablePIListPro(val: IProgramIncrementListExtended[]) {
    this.availablePIListPro.next(val);
  }
  public changeAvailablePIListSite(val: IProgramIncrementListExtended[]) {
    this.availablePIListSite.next(val);
  }
  public changeAvailablePIListGo(val: IProgramIncrementListExtended[]) {
    this.availablePIListGo.next(val);
  }
  public changeAvailablePIListFP(val: IProgramIncrementListExtended[]) {
    this.availablePIListFP.next(val);
  }
  public changeAvailablePIListCloud(val: IProgramIncrementListExtended[]) {
    this.availablePIListCloud.next(val);
  }
  public changeAvailablePIListDCP(val: IProgramIncrementListExtended[]) {
    this.availablePIListDCP.next(val);
  }
  public changeAvailablePIListTIAV17(val: IProgramIncrementListExtended[]) {
    this.availablePIListTIAV17.next(val);
  }
  public changeAvailablePIListSystemTest(val: IProgramIncrementListExtended[]) {
    this.availablePIListSystemTest.next(val);
  }
  public changeAvailablePIListKNX(val: IProgramIncrementListExtended[]) {
    this.availablePIListKNX.next(val);
  }
  public changeAvailablePIListMBus(val: IProgramIncrementListExtended[]) {
    this.availablePIListMBus.next(val);
  }

  public changeAvailableTCListPro(val: ITestCaseDataExtended[]) {
    this.availableTCListPro.next(val);
  }
  public changeAvailableTCListSite(val: ITestCaseDataExtended[]) {
    this.availableTCListSite.next(val);
  }
  public changeAvailableTCListGo(val: ITestCaseDataExtended[]) {
    this.availableTCListGo.next(val);
  }
  public changeAvailableTCListFP(val: ITestCaseDataExtended[]) {
    this.availableTCListFP.next(val);
  }
  public changeAvailableTCListCloud(val: ITestCaseDataExtended[]) {
    this.availableTCListCloud.next(val);
  }
  public changeAvailableTCListDCP(val: ITestCaseDataExtended[]) {
    this.availableTCListDCP.next(val);
  }
  public changeAvailableTCListTIAV17(val: ITestCaseDataExtended[]) {
    this.availableTCListTIAV17.next(val);
  }
  public changeAvailableTCListSystemTest(val: ITestCaseDataExtended[]) {
    this.availableTCListSystemTest.next(val);
  }
  public changeAvailableTCListKNX(val: ITestCaseDataExtended[]) {
    this.availableTCListKNX.next(val);
  }
  public changeAvailableTCListMBus(val: ITestCaseDataExtended[]) {
    this.availableTCListMBus.next(val);
  }
  //#endregion
}
