import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterApiService {

  constructor(
    private _http: HttpClient
  ) { }

  //#region Program Increments APIs
  public getProAndSitePIData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForProAndSite`);
  }

  public getGoPIData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForGo`);
  }

  public getFPPIData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForFP`);
  }

  public getCloudPIData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForCloud`);
  }

  public getDCPPIData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForDCP`);
  }

  public getTIAV17Data(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForTIAV17`);
  }

  public getSystemTestData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForSystemTest`)
  }

  public getKNXData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForKNX`)
  }

  public getMBusData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/programIncrementForMBus`)
  }
  //#endregion

  //#region Test cases APIs
  public getProTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/pro`);
  }

  public getSiteTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/site`);
  }

  public getGoTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/go`);
  }

  public getFPTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/fp`);
  }

  public getCloudTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/cloud`);
  }

  public getDCPTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/dcp`);
  }

  public getTIAV17TestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/tiav17`)
  }

  public getSystemTestTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/st`)
  }

  public getKNXTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/knx`)
  }

  public getMBusTestcaseData(): Observable<any> {
    return this._http.get(`${environment.resourceServerURL}/filter/testcases/mbus`)
  }
  //#endregion
}
