export enum ProductType {
  ABT_PRO = 'abt-pro',
  ABT_SITE = 'abt-site',
  ABT_GO = 'abt-go',
  ABT_FP = 'abt-fp',
  ABT_CLOUD = 'abt-cloud',
  ABT_DCP = 'abt-dcp',
  TIA_V17 = 'tia-v17',
  ABT_ST = "abt-st",
  ABT_KNX = "abt-knx",
  ABT_MBUS = "abt-mbus"
}

export enum ProductID {
  ABT_PRO = 1,
  ABT_SITE = 2,
  ABT_GO = 3,
  ABT_FP = 4,
  ABT_CLOUD = 5,
  ABT_DCP = 6,
  TIA_V17 = 7,
  ABT_ST = 8,
  ABT_KNX = 9
}
