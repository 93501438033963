<div class="chart-grid-container" (resized)="onResize($event.newWidth)">

  <ng-container [ngSwitch]="chartGridState">
    <ng-container *ngSwitchCase="renderingState">
      <mat-grid-list [cols]="columnNumber" rowHeight="6:5" gutterSize="20" *ngIf="chartData?.length">
        <mat-grid-tile *ngFor="let chartDataSingle of chartData; trackBy: trackByChartName">
          <app-line-chart
            [chartData]="chartDataSingle"
            [isExpandable]="true"
            (expandChart)="expandChart.emit($event)"
          ></app-line-chart>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>

    <ng-container *ngSwitchCase="resultNotFoundState">
      <si-empty-state
        icon="element-search"
        heading="No charts found!"
        content="Please redefine your filter."
      >
      </si-empty-state>
    </ng-container>

    <ng-container *ngSwitchCase="notInitializedState">
      <si-empty-state
        icon="element-trend"
        heading="Hi There"
        content="Please configure filter to generate charts."
      >
      </si-empty-state>
    </ng-container>

    <ng-container *ngSwitchCase="processingState">
      <div class="loader">
        <p-progressSpinner [style]="{width: '60px', height: '60px'}" strokeWidth="4" animationDuration=".8s"></p-progressSpinner>
        {{'CHART-GRID.PREPARING-CHARTS'| translate}}
      </div>
    </ng-container>

  </ng-container>
</div>
