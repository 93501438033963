import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
  production: true,

  resourceServerURL: 'https://abtperformance.com/api',

  authConfig: {
    issuer: 'https://login.microsoftonline.com/38ae3bcd-9579-4fd4-adda-b42e1495d55a/v2.0',
    redirectUri: `${window.location.origin}/`,
    clientId: 'de231820-0822-48fa-9f42-31852c01aaea',
    responseType: 'code',
    scope: 'email openid profile api://8eb8ae57-b88d-45e7-bd98-49ba869fb8df/PerformanceDashboardView',
    showDebugInformation: true,
    strictDiscoveryDocumentValidation: false,
  } as AuthConfig
};
