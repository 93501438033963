import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
} from '@angular/core';
import { IBTQDetails, IChartData } from '../../models/chart.model';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit {
  @Input() chartData: IChartData;
  @Input() isExpandable: boolean;
  color: string = "";
  @Output() expandChart = new EventEmitter<string>();

  data: any;

  options = {
    elements: {
      line: {
        cubicInterpolationMode: 'monotone',
        tension: 0,
      },
    },
    responsive: true,
    // animation: {
    //   duration: 0,
    // },
    // hover: {
    //   animationDuration: 0,
    // },
    // responsiveAnimationDuration: 0,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Time(sec)', // for displaying Y-axis label
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      mode: 'index', // mode: 'nearest' for showing single point
      position: 'nearest',
      intersect: false,
    },
  };

  getChartColor(lineDataName: string) {
    switch (lineDataName) {
      case 'SmallPrj':
      case 'Typical':
      case 'PXG3.W200-1':
        return {
          // Blue for small projects
          backgroundColor: 'rgba(100, 100, 100, 0)',
          borderColor: 'rgba(25, 145, 232, 1)',
          pointBackgroundColor: 'rgba(25, 145, 232, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(25, 145, 232, 1)',
        };

      case 'MediumPrj':
      case 'PXG3.W200-2':
        return {
          // Orange for medium projects
          backgroundColor: 'rgba(100, 100, 100, 0)',
          borderColor: 'rgba(244, 149, 66, 1)',
          pointBackgroundColor: 'rgba(244, 149, 66, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(244, 149, 66, 1)',
        };

      case 'LargePrj':
      case 'Action Time':
      case 'Maximum':
        return {
          // Red for large sized projects
          backgroundColor: 'rgba(100, 100, 100, 0)',
          borderColor: 'rgba(204, 24, 54, 1)',
          pointBackgroundColor: 'rgba(204, 24, 54, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(204, 24, 54, 1)',
        };

      case 'Acceptance':
        return {
          // Yellow for acceptance parameter
          backgroundColor: 'rgba(255, 255, 0, 0.2)',
          borderColor: 'rgba(198, 198, 11, 1)',
          pointBackgroundColor: 'rgba(198, 198, 11, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(198, 198, 11, 1)',
        }
      case 'Target':
        return {
          // Green for Target parameter
          backgroundColor: 'rgba(0, 179, 60, 0.3)',
          borderColor: 'rgba(0, 179, 60, 1)',
          pointBackgroundColor: 'rgba(0, 179, 60, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(0, 179, 60, 1)',
        };
      default:
        break;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.initializeChart();
  }

  private initializeChart() {
    this.data = this.getChartData();
    console.log(this.data);
  }

  getChartData(): {
    labels: string[];
    datasets: { label: string, data: number[] }[];
  } {
    const chartJSData = {
      labels: this.chartData?.chartLabels?.indexName || [],
      datasets: this.chartData?.chartLineData?.map((lineData) => {
        return {
          label: lineData.label,
          data: lineData.data,
          ...this.getChartColor(lineData.label)
        };
      }),
      btqDetailsSet: this.chartData?.btqDetails
    };

    console.log(chartJSData);
    return chartJSData;
  }

  onExpandChart(chartName: string) {
    this.expandChart.emit(chartName);
  }

  getDefectNumber(btqDetails: IBTQDetails) {
    if (btqDetails != null) {
      if (btqDetails.btqNumber != 0) {

        return btqDetails.btqNumber;
      }
    }
  }
  getColor(btqDetails: IBTQDetails) {
    if (btqDetails != null && btqDetails.btqNumber > 1) {
      switch (btqDetails.btqSeverity) {
        case 'Major':
          this.color = 'defectColorRed';
          break;
        case 'Minor':
          this.color = 'defectColorOrange';
          break;
        default:
          this.color = 'defectColorGreen';
          break;
      }
      return this.color;
    }

  }
  goToLink(btqDetails: IBTQDetails) {
    if (btqDetails != null) {
      if (btqDetails.btqNumber != 0) {
          window.open(btqDetails.btqUrl,'_blank');
      }
    }
  }
}
