<div class="toolbar-container">
  <div class="filter-side">
    <button type="button" class="btn btn btn-primary mr-5" [disabled]="filterDisabled" (click)="applyFilterMenu.toggle($event)">{{'TOOLBAR.APPLY-FILTER' | translate}}</button>
    <p-tieredMenu #applyFilterMenu [popup]="true" [model]="applyFilterMenuItem"></p-tieredMenu>

    <button type="button" class="btn btn btn-primary mr-5" [disabled]="filterDisabled" (click)="baselineMenu.toggle($event)">{{'TOOLBAR.BASELINE' | translate}}</button>
    <p-tieredMenu #baselineMenu [popup]="true" [model]="baselineMenuItem"></p-tieredMenu>

    <button pButton
      pRipple
      type="button"
      icon="pi pi-times"
      class="p-button-rounded p-button-text p-button-danger p-button-sm primeng-button-small"
      [disabled]="filterDisabled"
      *ngIf="isAnyFilterActive()"
      (click)="removeFilters()">
    </button>
  </div>

  <div class="chips-side">
    <span class="badge badge-primary">{{ productType | productName }}</span>
    <span class="badge badge-secondary" *ngIf="aboveAcceptanceActive">{{'TOOLBAR.ABOVE-ACCEPTANCE' | translate}}</span>
    <span class="badge badge-secondary" *ngIf="aboveTargetActive">{{'TOOLBAR.ABOVE_TARGET' | translate}}</span>
    <span class="badge badge-secondary" *ngIf="inclineActive">{{'TOOLBAR.INCLINED-BY' | translate}}: {{ inclineActive }}%</span>
    <span class="badge badge-secondary" *ngIf="declineActive">{{'TOOLBAR.DECLINED-BY' | translate}}: {{ declineActive }}%</span>
    <span class="badge badge-secondary" *ngIf="baseLineActive.length">{{'TOOLBAR.BASE-LINE' | translate}}: {{ baseLineActive }}</span>
  </div>
  <div class="search-container">
    <input
      type="text"
      class="form-control"
      placeholder="Search"
      (change)="searchEntered($event.target.value)"
      [disabled]="filterDisabled">
  </div>
</div>
