<div class="filter-container">
  <accordion *ngIf="localFilterConfig">

    <accordion-group [panelClass]="'accordion-content'" #deviceTypeAccordionGroupRef *ngIf="localFilterConfig?.deviceTypes">
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">{{'FILTER.HEADER-TITLE.DEVICE-TYPE' | translate}}</div>
        <span class="accordion-header-icon">
          <i [class]=" deviceTypeAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="radio-button-container mb-2" *ngFor="let radio of localFilterConfig?.deviceTypes">
        <input type="radio" [id]="radio.name" [value]="radio.id" [checked]="radio.checked" (change)="changeDeviceType($event.target.value)">
        <label class="ellipses" [for]="radio.name">{{ radio.name }}</label>
      </div>
    </accordion-group>
    <accordion-group [panelClass]="'accordion-content'" #platformTypeAccordionGroupRef *ngIf="localFilterConfig?.platformType">
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">Platform Type</div>
        <span class="accordion-header-icon">
          <i [class]=" platformTypeAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="radio-button-container mb-2" *ngFor="let radio of localFilterConfig?.platformType">
        <input type="radio" [id]="radio.name" [value]="radio.id" [checked]="radio.checked" (change)="changePlatformType($event.target.value)">
        <label class="ellipses" [for]="radio.name">{{ radio.name }}</label>
      </div>
    </accordion-group>
    <accordion-group [panelClass]="'accordion-content'" #networkTypeAccordionGroupRef *ngIf="localFilterConfig?.networkTypes">
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">{{'FILTER.HEADER-TITLE.NETWORK-TYPE' | translate}}</div>
        <span class="accordion-header-icon">
          <i [class]=" networkTypeAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="radio-button-container mb-2" *ngFor="let radio of localFilterConfig?.networkTypes">
        <input type="radio" [id]="radio.name" [value]="radio.id" [checked]="radio.checked" (change)="changeNetworkType($event.target.value)">
        <label class="ellipses" [for]="radio.name">{{ radio.name }}</label>
      </div>
    </accordion-group>

    <accordion-group [panelClass]="'accordion-content'" #machineConfAccordionGroupRef>
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">{{'FILTER.HEADER-TITLE.MACHINE-CONFIGURATION' | translate}}</div>
        <span class="accordion-header-icon">
          <i [class]="machineConfAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="radio-button-container mb-2" *ngFor="let radio of localFilterConfig?.machineConfigs">
        <input type="radio" [id]="radio.name" [value]="radio.id" [checked]="radio.checked" (change)="changeMachineConfig($event.target.value)">
        <label class="ellipses" [for]="radio.name">{{ radio.name }}</label>
      </div>
    </accordion-group>

    <accordion-group [panelClass]="'accordion-content'" #programIncrementAccordionGroupRef>
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">{{'FILTER.HEADER-TITLE.PROGRAM-INCREMENTS' | translate}}</div>
        <span class="accordion-header-icon">
          <i [class]="programIncrementAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="checkbox-container mb-2" *ngFor="let pi of localFilterConfig?.programIncrementsList">
        <input type="checkbox" [id]="pi.piName" [(ngModel)]="pi.checked">
        <label class="ellipses" [for]="pi.piName">{{ pi.piName }}</label>
      </div>
    </accordion-group>

    <accordion-group [panelClass]="'accordion-content'" #testCasesAccordionGroupRef>
      <button class="btn btn-link btn-block accordion-header-btn" accordion-heading type="button">
        <div class="accordion-header-title">{{'FILTER.HEADER-TITLE.TEST-CASES' | translate}}</div>
        <span class="accordion-header-icon">
          <i [class]="testCasesAccordionGroupRef?.isOpen ? 'icon element-up-2' : 'icon element-down-2'"></i>
        </span>
      </button>
      <div class="checkbox-container mb-2" *ngFor="let tc of localFilterConfig?.testCasesList">
        <input type="checkbox" [id]="tc.actionId" [(ngModel)]="tc.checked">
        <label class="ellipses" [for]="tc.actionId">{{ tc.tagName }}</label>
      </div>
    </accordion-group>
  </accordion>

  <div class="actions-container">
    <button class="btn btn-secondary" (click)="selectAll()" [disabled]="filterButtonsDisabled">Select All</button>
    <button class="btn btn-secondary" (click)="clearAll()" [disabled]="filterButtonsDisabled">Clear All</button>
    <button class="btn btn-primary" (click)="generate()" [disabled]="!isGenerateAvailable()">Generate</button>
  </div>
</div>
