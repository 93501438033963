import { NgModule, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ApplicationRef } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiLandingPageModule } from '@simpl/element-ng';

import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SharedModule, AuthApiService } from './shared';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGuard } from './shared/guards/auth.guard';
import { environment } from 'src/environments/environment';
//import { HowItWorksComponent } from './pages/main/how-it-works/how-it-works.component';

const routes: Routes = [
  { path: 'landing', component: LandingComponent, },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)
  },
  { path: '**', redirectTo: 'landing' }
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
//    HowItWorksComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    SiLandingPageModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.resourceServerURL
        ],
        sendAccessToken: true
      }
    })
  ],
  exports: [RouterModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule implements DoBootstrap {

  constructor(private authApiService: AuthApiService) { }

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.authApiService.initializeAuthenticationOnBootstrap()
      .then(() => {
        appRef.bootstrap(AppComponent);
      })
      .catch((error) => {
        console.error(`[ngDoBootstrap] Problem while authService.initializeAuthenticationOnBootstrap(): ${JSON.stringify(error)}`, error);
      });
  }

}
