import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '../enums/product.enum';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(value: ProductType, ...args: unknown[]): string {
    switch (value) {
      case ProductType.ABT_PRO:
        return 'ABT Pro';

      case ProductType.ABT_SITE:
        return 'ABT Site';

      case ProductType.ABT_GO:
        return 'ABT Go';

      case ProductType.ABT_FP:
        return 'ABT FP';

      case ProductType.ABT_CLOUD:
        return 'ABT Cloud';
    }
  }

}
