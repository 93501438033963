import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFilterConfigurations } from '../../models/filter.model';
import { ProductType } from '../../enums/product.enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() productType: ProductType;
  @Input() filterConfigurations: IFilterConfigurations;
  @Input() filterButtonsDisabled: boolean;

  @Output() generateCharts = new EventEmitter<IFilterConfigurations>();

  localFilterConfig: IFilterConfigurations;

  constructor() { }

  ngOnInit(): void {
    this.localFilterConfig = {...this.filterConfigurations};
  }

  changeDeviceType(id: string) {
    this.localFilterConfig.deviceTypes.forEach(device => {
      if (device.id === Number(id)) {
        device.checked = true;
      } else {
        device.checked = false;
      }
    })
  }
  changePlatformType(id: string) {
    this.localFilterConfig.platformType.forEach(platform => {
      if (platform.id === Number(id)) {
        platform.checked = true;
      } else {
        platform.checked = false;
      }
    })
  }

  changeNetworkType(id: string) {
    this.localFilterConfig.networkTypes.forEach(network => {
      if (network.id === Number(id)) {
        network.checked = true;
      } else {
        network.checked = false;
      }
    })
  }

  changeMachineConfig(id: string) {
    this.localFilterConfig.machineConfigs.forEach(mConfig => {
      if (mConfig.id === Number(id)) {
        mConfig.checked = true;
      } else {
        mConfig.checked = false;
      }
    })
  }

  selectAll() {
    this.localFilterConfig.programIncrementsList.forEach(piData => piData.checked = true);
    this.localFilterConfig.testCasesList.forEach(tcData => tcData.checked = true);
  }

  clearAll() {
    this.localFilterConfig.programIncrementsList.forEach(piData => piData.checked = false);
    this.localFilterConfig.testCasesList.forEach(tcData => tcData.checked = false);
  }

  generate() {
    this.generateCharts.emit(this.localFilterConfig);
  }

  isGenerateAvailable() {
    return (this.localFilterConfig.programIncrementsList.findIndex(piData => piData.checked === true) !== -1) &&
    (this.localFilterConfig.testCasesList.findIndex(tcData => tcData.checked === true) !== -1) &&
    !this.filterButtonsDisabled;
  }

}
