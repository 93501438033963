import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  FilterComponent,
  LineChartComponent,
  ToolbarComponent,
  ChartGridComponent,
} from './components';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { SiEmptyStateModule, SiLoadingSpinnerModule, SiContentActionBarModule } from '@simpl/element-ng';
import { FormsModule } from '@angular/forms';
import { ProductNamePipe } from './pipes/product-name.pipe';

import { TieredMenuModule } from 'primeng/tieredmenu';
import { ButtonModule } from 'primeng/button';

import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartModule } from 'primeng/chart';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    MatGridListModule,
    SiEmptyStateModule,
    SiLoadingSpinnerModule,
    SiContentActionBarModule,
    TieredMenuModule,
    ButtonModule,
    AngularResizedEventModule,
    ChartModule,
    ProgressSpinnerModule
  ],
  exports: [
    TranslateModule,

    // Components
    ChartGridComponent,
    LineChartComponent,
    FilterComponent,
    ToolbarComponent,

    // Services

    // Models
  ],
  declarations: [
    FilterComponent,
    LineChartComponent,
    ToolbarComponent,
    ChartGridComponent,
    ProductNamePipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
