<div class="line-chart-container" *ngIf="chartData && data">
  <div class="chart-header-defect-container">
    <div class="chart-header ellipses" (click)="onExpandChart(chartData.tagName)" *ngIf="isExpandable">
      {{ chartData.tagName || 'Loading...' }}
      
    </div>
    <div [ngClass]="getColor(chartData.btqDetails)"(click)="goToLink(chartData.btqDetails)">{{getDefectNumber(chartData.btqDetails)}}</div>
  </div>
  <div class="chart-content">
    <p-chart type="line"
      [height]="'100%'"
      [width]="'100%'"
      [data]="data"
      [options]="options"
      [responsive]="true"
    ></p-chart>
  </div>
</div>
