import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private oauthService: OAuthService) { }

  async initializeAuthenticationOnBootstrap() {
    this.oauthService.configure(environment.authConfig);
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public isAuthenticated() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  async login() {
    return this.oauthService.initCodeFlow();
  }

  async getUserData(): Promise<UserInfo> {
    const claims = await this.oauthService.getIdentityClaims();
    return claims as UserInfo;
  }

  async getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  async logout() {
    return this.oauthService.logOut();
  }  
}
